/* Firefox */
.datepicker-scrollbar {
  --datepicker-scroll-color: #999;
  scrollbar-width: auto;
  scrollbar-color: var(--datepicker-scroll-color) #ffffff;
}

.datepicker-scrollbar::-webkit-scrollbar {
  width: 0px;
}

.datepicker-scrollbar::-webkit-scrollbar-track {
  background: #ffffff;
}

.datepicker-scrollbar::-webkit-scrollbar-thumb {
  background-color: var(--datepicker-scroll-color);
  border-radius: 10px;
}
