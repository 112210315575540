.rs-theme-light,
:root {
  --rs-primary-50: #f2e9ec;
  --rs-primary-100: #d9bcc7;
  --rs-primary-150: #d9bcc7;
  --rs-primary-200: #bf90a2;
  --rs-primary-250: #a5647cab;
  --rs-primary-300: #a5647c;
  --rs-primary-400: #994d69;
  --rs-primary-500: #7f2144;
  --rs-primary-600: #661a36;
  --rs-primary-700: #591730;
  --rs-primary-800: #401122;
  --rs-primary-900: #260a14;

  --rs-white: #fff;

  --rs-gray-0: var(--rs-white);
  --rs-gray-50: #efefef;
  --rs-gray-100: #e6e6e6;
  --rs-gray-200: #cccccc;
  --rs-gray-300: #b3b3b3;
  --rs-gray-400: #999999;
  --rs-gray-500: #808080;
  --rs-gray-600: #666666;
  --rs-gray-700: #4d4d4d;
  --rs-gray-800: #333333;
  --rs-gray-900: #1a1a1a;

  --rs-error-50: #fad7dc;
  --rs-error-100: #f18f9d;
  --rs-error-150: #f18f9d;
  --rs-error-200: #ee7385;
  --rs-error-300: #eb5f74;
  --rs-error-400: #e94b62;
  --rs-error-500: #e63751;
  --rs-error-600: #b82c41;
  --rs-error-700: #a12739;
  --rs-error-800: #731c29;
  --rs-error-900: #451018;

  --rs-success-100: #c3f8d1;
  --rs-success-500: #37e666;
  --rs-success-600: #2cb852;
  --rs-success-700: #218a3d;
  --rs-success-800: #165c29;

  --rs-info-700: #19688d;

  --rs-form-errormessage-text: var(--rs-gray-50);
  --rs-form-errormessage-bg: var(--rs-error-400);
  --rs-form-errormessage-border: var(--rs-error-300);

  --rs-divider-border: var(--rs-primary-300);
  --rs-border-primary: var(--rs-gray-200);
  --rs-input-disabled-bg: #f7f7fa;

  --rs-rate-symbol: var(--rs-gray-200);

  --rs-loader-ring: rgb(from #d9bcc7 r g b/80%);
  --rs-loader-rotor: var(--rs-primary-400);

  /** BTNs **/
  --rs-btn-default-bg: var(--rs-primary-50);
  --rs-btn-default-text: var(--rs-primary-800);
  --rs-btn-default-hover-bg: var(--rs-primary-100);
  --rs-btn-default-active-bg: var(--rs-primary-150);
  --rs-btn-default-active-text: var(--rs-primary-900);
  --rs-btn-default-disabled-bg: var(--rs-primary-50);
  --rs-btn-default-disabled-text: var(--rs-gray-400);
  /** end BTNs **/

  --swiper-pagination-color: var(--rs-primary-500);
}
.__datepicker.__datepicker-theme-green * {
  --primary: var(--rs-primary-500);
  --secondary: var(--rs-primary-300);
}
.__datepicker-pick-day-container
  .__datepicker-pick-day
  .__datepicker-days:hover:not(.__datepicker-day-disabled):not(.__datepicker-today):not(
    .__datepicker-selected
  ) {
  background-color: var(--secondary);
  color: #fff;
}
.rs-avatar.hover-opacity {
  position: relative;
}
.rs-avatar.hover-opacity:hover:after {
  content: '';
  z-index: 1;
  background-color: var(--rs-error-500);
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.5;
  width: 100%;
  height: 100%;
}
