[data-bs-theme='light'] {
  --rs-primary-50: #f2e9ec;
  --rs-primary-100: #d9bcc7;
  --rs-primary-150: #d9bcc7;
  --rs-primary-200: #bf90a2;
  --rs-primary-250: #a5647cab;
  --rs-primary-300: #a5647c;
  --rs-primary-400: #994d69;
  --rs-primary-450: #922f53;
  --rs-primary-500: #7f2144;
  --rs-primary-600: #661a36;
  --rs-primary-700: #591730;
  --rs-primary-800: #401122;
  --rs-primary-900: #260a14;

  --rs-secondary: #ead196;
  --rs-secondary-light: #f3e9d2;
  --rs-secondary-opace: #ead19660;
  --bs-body-bg: rgb(255, 255, 255);
  --bs-body-color-rgb: 255, 255, 255;
  /* --bs-body-bg: rgb(250, 246, 247);
  --bs-body-color-rgb: 250, 246, 247; */

  --bs-tertiary-bg: rgb(217, 188, 199);
  --bs-tertiary-bg-rgb: 217, 188, 199;

  --bs-primary: #7f2144;
  --bs-primary-rgb: 127, 33, 68;

  --bs-secondary: #ead196;
  --bs-secondary-rgb: 234, 209, 150;
  --bs-border-color-translucent: rgba(165, 100, 124, 0.3);

  --bs-emphasis-color-rgb: 217, 188, 199;
  --bs-navbar-active-color: rgba(var(--bs-emphasis-color-rgb), 1);

  /* accordion */
  --bs-border-color: var(--bs-border-color-translucent);
  --bs-primary-bg-subtle: #7f2144;
  --bs-primary-text-emphasis: var(--rs-primary-50);
  --bs-accordion-active-color: var(--bs-primary-bg-subtle);
  --bs-accordion-active-bg: var(--bs-primary-text-emphasis);
  --bs-accordion-btn-focus-border-color: var(--bs-primary-bg-subtle);

  --bs-dropdown-link-active-bg: rgb(217, 188, 199);
  --bs-border-radius: 0.75rem;
}
.accordion-button {
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%237f2144'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23f2e9ec'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
.btn-primary {
  --bs-btn-color: #eee;
  --bs-btn-bg: #7f2144;
  --bs-btn-border-color: var(--bs-btn-bg);
  --bs-btn-hover-color: var(--bs-btn-color);
  --bs-btn-hover-bg: #994d69;
  --bs-btn-hover-border-color: var(--bs-btn-hover-bg);
  --bs-btn-focus-shadow-rgb: 127, 33, 68;
  --bs-btn-active-color: var(--bs-btn-color);
  --bs-btn-active-bg: var(--bs-btn-hover-bg);
  --bs-btn-active-border-color: var(--bs-btn-hover-bg);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: var(--bs-btn-color);
  --bs-btn-disabled-bg: var(--bs-btn-bg);
  --bs-btn-disabled-border-color: var(--bs-btn-bg);
}
.btn-secondary {
  --bs-btn-color: var(--rs-primary-500);
  --bs-btn-bg: var(--bs-secondary);
  --bs-btn-border-color: var(--bs-secondary);
  --bs-btn-hover-color: var(--rs-primary-500);
  --bs-btn-hover-bg: var(--rs-primary-50);
  --bs-btn-hover-border-color: var(--rs-primary-50);
  --bs-btn-focus-shadow-rgb: 127, 33, 68;
  --bs-btn-active-color: var(--bs-btn-color);
  --bs-btn-active-bg: var(--bs-btn-hover-bg);
  --bs-btn-active-border-color: var(--bs-btn-hover-bg);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: var(--bs-btn-color);
  --bs-btn-disabled-bg: var(--bs-btn-bg);
  --bs-btn-disabled-border-color: var(--bs-btn-bg);
}
.navbar,
.navbar-nav,
.nav-link {
  --bs-navbar-color: var(--rs-primary-50);
  --bs-nav-link-color: var(--rs-primary-50);
  --bs-navbar-hover-color: var(--rs-primary-50);
  /* --bs-nav-link-color: var(--rs-primary-100); */
  /* --bs-nav-link-color: rgba(127, 33, 68, 0.65); */
}
/* .nav-link:hover,
.nav-link:focus {
  --bs-navbar-color: #f3e4ea;
  --bs-nav-link-color: #f3e4ea;
} */
.nav-link.active {
  color: var(--rs-primary-100) !important;
}
a,
a:hover,
a:active,
a:focus {
  color: var(--bs-navbar-color);
  text-decoration: none;
}
a:hover,
a:active,
a:focus {
  color: var(--rs-primary-100);
}
a.col-11:hover,
a.col-11:active,
a.col-11:focus {
  color: var(--rs-primary-400);
}
.dropdown-item.active,
.dropdown-item:active {
  background-color: var(--rs-primary-200) !important;
}
.card {
  --bs-card-spacer-y: 2rem;
  --bs-card-spacer-x: 2rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-title-color: ;
  --bs-card-subtitle-color: ;
  --bs-card-border-width: var(--bs-border-width);
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: var(--bs-border-radius);
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: rgba(var(--bs-body-color-rgb), 0.03);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: var(--bs-body-bg);
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
}
.bg-secondary {
  background-color: var(--rs-secondary);
}
.btn-lg {
  --bs-border-radius: 0.9rem;
}
.accordion {
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0);
}
.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}
/* div.background-gradient-first {
  background: radial-gradient(
    87.68% 101.73% at 51.86% -0.36%,
    rgba(127, 33, 68, 0.28) 0,
    rgba(13, 30, 51, 0) 100%
  );
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 1000px;
  z-index: 0;
} */
body {
  font-family:
    Vazirmatn FD,
    sans-serif;
  /* min-height: 20000px; */
}
p {
  line-height: 2;
}

.navbar-placeholder {
  height: 96px;
}
.navbar {
  position: fixed;
  z-index: 100;
  top: 0;
  border-radius: 10px;
  left: 50%;
  transform: translateX(-50%);
  padding-top: 24px;
  padding-bottom: 24px;
}

@media screen and (max-width: 768px) {
  .navbar {
    background-color: var(--rs-primary-50);
    border-radius: 0;
  }
}
.heading-text {
  font-size: 40px;
  /* padding-top: 60px; */
  max-width: 80%;
  line-height: 1.5;
  font-weight: bold;
  color: var(--rs-primary-800);
}
.sub-heading-text {
  font-size: 18px;
  width: 90%;
  max-width: 800px;
  margin-top: 16px;
  line-height: 2;
  color: var(--rs-primary-800);
}
@media screen and (max-width: 992px) {
  .heading-text {
    margin-top: 32px;
    font-size: 30px;
    max-width: 100%;
    line-height: 1.5;
    text-align: center;
  }
  .sub-heading-text {
    font-size: 18px;
    width: 90%;
    margin-inline: auto;
    text-align: center;
  }
}

.info-cards .card {
}

.how-to-use {
  margin-top: 4rem;
  padding-inline: 0%;
  text-align: justify;
}
.price-font {
  font-size: 64px;
}

.hidden {
  display: none;
}
.plans-list li {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}
.plans-list li:before {
  content: '';
  display: inline-block;
  width: 20px;
  aspect-ratio: 1;
  margin-left: 8px;
  ackground-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.plans-list li.checked:before {
  content: '';
  background-image: url('data:image/svg+xml,<svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M12 22C7.28595 22 4.92893 22 3.46447 20.5355C2 19.0711 2 16.714 2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28595 2 12 2C16.714 2 19.0711 2 20.5355 3.46447C22 4.92893 22 7.28595 22 12C22 16.714 22 19.0711 20.5355 20.5355C19.0711 22 16.714 22 12 22ZM16.0303 8.96967C16.3232 9.26256 16.3232 9.73744 16.0303 10.0303L11.0303 15.0303C10.7374 15.3232 10.2626 15.3232 9.96967 15.0303L7.96967 13.0303C7.67678 12.7374 7.67678 12.2626 7.96967 11.9697C8.26256 11.6768 8.73744 11.6768 9.03033 11.9697L10.5 13.4393L14.9697 8.96967C15.2626 8.67678 15.7374 8.67678 16.0303 8.96967Z" fill="%237f2144"/></svg>');
}
.plans-list li:not(.checked):before {
  content: '';
  background-image: url('data:image/svg+xml,<svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M12 22C7.28595 22 4.92893 22 3.46447 20.5355C2 19.0711 2 16.714 2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28595 2 12 2C16.714 2 19.0711 2 20.5355 3.46447C22 4.92893 22 7.28595 22 12C22 16.714 22 19.0711 20.5355 20.5355C19.0711 22 16.714 22 12 22ZM16.0303 8.96967C16.3232 9.26256 16.3232 9.73744 16.0303 10.0303L11.0303 15.0303C10.7374 15.3232 10.2626 15.3232 9.96967 15.0303L7.96967 13.0303C7.67678 12.7374 7.67678 12.2626 7.96967 11.9697C8.26256 11.6768 8.73744 11.6768 9.03033 11.9697L10.5 13.4393L14.9697 8.96967C15.2626 8.67678 15.7374 8.67678 16.0303 8.96967Z" fill="%23373737"/></svg>');
}

.card-recomended {
  border-width: 8px;
  position: relative;
  border-color: var(--bs-primary);
  z-index: 1;
  top: -8px;
  border-radius: 8px 8px 28px 28px;
}
.card-recomended .recomended-round-right,
.card-recomended .recomended-round-left {
  content: '';
  display: block;
  width: 30px;
  height: 30px;
  background-color: var(--bs-primary);
  position: absolute;
  top: 0;
  left: 0;
}
.card-recomended .recomended-round-right:after,
.card-recomended .recomended-round-left:after {
  content: '';
  display: block;
  width: 40px;
  height: 40px;
  background-color: var(--bs-body-bg);
  position: absolute;
  border-radius: 20px 10px 20px 10px;
  top: 0;
  left: 0;
}
.card-recomended .recomended-round-right:after {
  border-radius: 10px 20px 10px 20px;
  left: unset;
  right: 0;
}
.card-recomended .recomended-round-right {
  left: unset;
  right: 0;
}
.card-recomended:before {
  content: 'پلن پیشنهادی';
  background-color: var(--bs-primary);
  color: #000;
  display: block;
  border-radius: 28px 28px 0 0;
  text-align: center;
  position: absolute;
  font-weight: bold;
  padding: 8px 0 8px 0;
  width: calc(100% + 16px);
  border: 1px solid var(--bs-primary);
  top: -44px;
  left: -8px;
}

.price-cards {
  margin-top: 64px;
}

.why-us p {
  line-height: 2;
  text-align: justify;
}

.text-justify {
  text-align: justify;
}

.devices-download svg {
  width: 64px;
  height: 64px;
}

.image-icon {
  width: 100%;
  aspect-ratio: 1;
  margin-inline: auto;
  display: block;
}

.swiper-button-next,
.swiper-button-prev {
  /* position: absolute;
  top: var(--swiper-navigation-top-offset, 50%);
  width: calc(var(--swiper-navigation-size) / 44* 27);
  height: var(--swiper-navigation-size);
  margin-top: calc(0px -(var(--swiper-navigation-size) / 2));
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center; */
  --swiper-navigation-color: var(--rs-primary-500);
  background-color: #a5647c20;
  --swiper-navigation-size: 24px;
  height: 100% !important;
  transform: translateY(-44%);
  width: 30px !important;
  --swiper-navigation-sides-offset: 0px;
}
.swiper-button-next {
  border-radius: 10px 0 0 10px;
}

.swiper-button-prev {
  border-radius: 0 10px 10px 0;
}

.footer-pages {
  text-align: right;
}

.dropdown-menu-color {
  /* --bs-dropdown-link-color: #fff; */
}
@media screen and (max-width: 992px) {
  .footer-pages {
    text-align: center;
  }
  .footer-pages ul {
    list-style: none;
  }
  .dropdown-menu-color {
    background-color: var(--rs-primary-500);
    --bs-dropdown-link-color: #fff;
  }
}

.blog .card-text {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.circle-container {
  margin: auto;
  margin-block: 120px;
  position: relative;
  width: 800px;
  height: 800px;
  list-style-type: none;
  border-radius: 50%;
  display: grid;
  place-items: center;
  transform: rotate(-90deg);
}

.circle-item-center,
.circle-item {
  position: absolute;
  width: 180px;
  height: 180px;
  font-weight: bold;
  background-color: var(--rs-primary-500);
  border-radius: 50%;
  display: grid;
  place-items: center;
}
.circle-item {
  cursor: pointer;
  transition-duration: 0.5s;
}
.circle-item:hover {
  width: 240px;
  height: 240px;
  transition-duration: 0.5s;
}
.circle-info {
  transition-duration: 0.5s;
  font-size: 1.5rem;
}
.circle-item:hover .circle-info {
  transition-duration: 0.5s;
  font-size: 1.5rem;
}
.line-item {
  position: absolute;
  width: 300px;
  height: 4px;
  /* border-radius: 50%; */
  display: grid;
  place-items: center;
  background-color: var(--rs-primary-200);
  z-index: -1;
}

.circle-info {
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  transform: rotate(90deg);
  font-size: 1rem;
  padding: 12px;
  text-align: center;
  color: var(--bs-secondary);
}
.circle-info:visited,
.circle-info:focus,
.circle-info:active,
.circle-info:hover {
  color: var(--bs-secondary) !important;
}

@media screen and (max-width: 992px) {
  .circle-container {
    margin-block: 80px;
    width: 500px;
    height: 500px;
  }
  .circle-item-center,
  .circle-item {
    position: absolute;
    width: 120px;
    height: 120px;
  }
  .line-item {
    width: 200px;
  }
  .circle-item:hover {
    width: 180px;
    height: 180px;
    transition-duration: 0.5s;
  }
  .circle-info {
    font-size: 1rem;
  }
  .circle-item:hover .circle-info {
    transition-duration: 0.5s;
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 600px) {
  .circle-container {
    margin-block: 70px;
    width: 300px;
    height: 300px;
  }
  .circle-item-center,
  .circle-item {
    position: absolute;
    width: 80px;
    height: 80px;
  }
  .line-item {
    width: 100px;
  }
  .circle-info {
    font-size: 0.6rem;
    transition-duration: 0.5s;
  }

  .circle-item:hover {
    width: 120px;
    height: 120px;
    transition-duration: 0.5s;
  }
  .circle-item:hover .circle-info {
    transition-duration: 0.5s;
    font-size: 1.2rem;
  }
}

.center-justified {
  text-align: justify;
  text-align-last: center;
}

.dropdown-item.dropdown-toggle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.certificates img {
  width: 100%;
}

#to-top {
  display: none; /* Hidden by default */
  position: fixed; /* Fixed/sticky position */
  bottom: 20px; /* Place the button at the bottom of the page */
  right: 30px; /* Place the button 30px from the right */
  z-index: 99; /* Make sure it does not overlap */
  border: none; /* Remove borders */
  outline: none; /* Remove outline */
  background-color: var(--rs-primary-450); /* Set a background color */
  color: white; /* Text color */
  cursor: pointer; /* Add a mouse pointer on hover */
  padding: 12px; /* Some padding */
  border-radius: 1000px; /* Rounded corners */
}

#to-top img {
  width: 32px;
  height: 32px;
}
#to-top:hover {
  background-color: var(--rs-primary-300); /* Add a dark-grey background on hover */
}
