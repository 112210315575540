#webpack-dev-server-client-overlay {
  display: none;
}
body {
  /* font-family: 'Vazirmatn-FD', sans-serif; */
  font-family: 'Vazirmatn-FD-FD', sans-serif;
  background-color: var(--rs-primary-50);
  color: var(--rs-gray-800);
}
.heading-text {
  font-size: 40px;
  max-width: 80%;
  line-height: 1.5;
  font-weight: bold;
  color: var(--rs-primary-800);
}
.sub-heading-text {
  font-size: 18px;
  width: 90%;
  max-width: 800px;
  margin-top: 16px;
  line-height: 2;
  color: var(--rs-primary-800);
}
@media screen and (max-width: 992px) {
  .heading-text {
    margin-top: 32px;
    font-size: 30px;
    max-width: 100%;
    line-height: 1.5;
    text-align: center;
  }
  .sub-heading-text {
    font-size: 18px;
    width: 90%;
    margin-inline: auto;
    text-align: center;
  }
}

.font-fd {
  font-family: 'Vazirmatn-FD-FD', sans-serif !important;
}
.font-en {
  font-family: 'Vazirmatn-FD', sans-serif !important;
}
.Toastify__toast-body > div:last-child,
.Toastify__toast-body {
  font-family: 'Vazirmatn-FD' !important;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

/* rsuite overrides */
.rs-form:not(.rs-form-inline) .rs-form-group:not(:last-child) {
  margin-bottom: 0px;
}

.rs-form-control-wrapper > .rs-input,
.rs-form-control-wrapper > .rs-input-number {
  max-width: 100%;
}
.rs-form-group.full .rs-input-group,
.rs-form-group.full .rs-form-control-wrapper,
.rs-form-group.full .rs-form-control-wrapper > .rs-input,
.rs-form-group.full .rs-form-control-wrapper > .rs-input-number {
  width: 100%;
}

.rs-picker-toggle-wrapper .rs-picker-toggle.rs-btn[dir='rtl'],
.rs-picker-has-value.rs-picker-cleanable .rs-picker-toggle.rs-btn[dir='rtl'] {
  padding-left: 32px;
  padding-right: 11px;
  text-align: right;
}

.rs-picker-toggle.rs-btn[dir='rtl'] .rs-picker-toggle-indicator .rs-picker-caret-icon,
.rs-picker-toggle.rs-btn[dir='rtl'] .rs-picker-toggle-indicator .rs-picker-clean {
  left: 7px;
  right: unset;
}
/* .rs-picker-toggle-wrapper
  .rs-picker-toggle.rs-btn[dir='rtl'] */
/* +  */
.rs-picker-cleanable .rs-picker-textbox {
  margin-right: 0;
  margin-left: 44px;
}
/* !may have problem on eng */
.rs-picker-popup {
  text-align: right;
}

.rs-checkbox[dir='rtl'] .rs-checkbox-checker {
  padding-left: 0px;
  padding-right: 36px;
}

.rs-checkbox[dir='rtl'] .rs-checkbox-wrapper {
  left: unset;
  right: 10px;
}

.nav-item,
.nav-item:hover {
  text-decoration: none !important;
}
.nav-active {
  background-color: var(--rs-primary-250);
}

.pretty-scroll::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  cursor: auto;
}

.pretty-scroll::-webkit-scrollbar-track {
  background-color: var(--rs-primary-50);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

.pretty-scroll::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background-color: var(--rs-primary-300);
}

.custom-navlink-secondary button {
  background-color: var(--rs-primary-200) !important;
  color: var(--rs-primary-800) !important;
  font-size: 15px;
  font-family: 'Vazirmatn-FD-FD';
  /* display: flex !important
; */
  /* align-items: center; */
  padding-bottom: 13px;
  font-weight: 500;
}
.custom-navlink-dropdown button {
  height: 100% !important;
  border-radius: 0;
}
.custom-navlink-dropdown .custom-navlink-dropdown {
  min-height: 100%;
  height: unset !important;
}
.custom-navlink-dropdown .rs-dropdown-menu {
  width: 100%;
  text-decoration: rtl;
  text-align: right;
}
.custom-navlink-dropdown .rs-dropdown-toggle-caret {
  /* top: 18px; */
  right: unset;
  left: 12px;
}
@media screen and (min-width: 1024px) {
  .custom-navlink-dropdown .rs-dropdown-toggle-caret {
    top: 18px;
  }
}

.custom-navlink-dropdown .rs-dropdown-toggle,
.rs-dropdown-toggle.rs-btn {
  padding-left: 32px;
  padding-right: 12px;
}

.rs-radio-group[dir='rtl'] .rs-radio-checker {
  padding-left: 0px;
  padding-right: 36px;
}
.rs-radio-group[dir='rtl'] .rs-radio-wrapper {
  left: unset;
  right: 0;
}

.rs-picker-disabled,
.rs-input:disabled {
  opacity: 0.9;
  color: var(--rs-primary-600) !important;
  --rs-picker-value: var(--rs-primary-600);
  --rs-text-secondary: var(--rs-primary-600);
}

@media screen and (max-width: 1024px) {
  .navbar-mobile {
    height: 64px;
    overflow-y: hidden;
  }
  .navbar-mobile.open {
    height: unset;
    overflow-y: visible;
  }
}

.ellipsis-consultant {
  height: 56px;
  overflow: hidden;
  text-overflow: ellipsis;
  /* max-width: 100%; */
  display: -webkit-box;
  max-width: 200px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
